import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { get } from "lodash";

import Header from "./components/Header";
import Disclaimer from "./components/Disclaimer";
import Invalid from "./components/Invalid";
import Normal from "./components/Normal";
import ToBeRead from "./components/ToBeRead";

function FreeTextReport({ pageContent, answers }) {
  const headers = _.get(pageContent, ["headers"]);

  const report = _.get(pageContent, ["report"]);
  const config = _.get(pageContent, ["config"]);
  const imageType = _.get(pageContent, ["image_type"]);

  return (
    <div className="max-w-xl mx-auto">
      <table>
        <tbody>
          <tr>
            <td>
              <div>
                <Header pageContent={pageContent} />
              </div>
              {imageType === "invalid" && <Invalid pageContent={pageContent} />}
              {imageType === "normal" && <Normal pageContent={pageContent} />}
              {imageType === "toberead" && <ToBeRead pageContent={pageContent} />}
              <section className="py-8 space-y-4">
                {imageType === "abnormal" && (
                  <div className="space-y-4">
                    <div>
                      <h2 className="font-semibold text-base">
                        {" "}
                        {_.startCase(_.toLower(headers.header_impression))}:
                      </h2>
                      <p className="text-xs">{report?.impression}</p>
                    </div>
                    <div>
                      <h2 className="font-semibold text-base capitalize">
                        {_.startCase(_.toLower(headers.header_findings))}:
                      </h2>
                      <pre className="text-xs">
                        <div
                          className="dangerous-text"
                          dangerouslySetInnerHTML={{ __html: report?.findings }}
                        />
                      </pre>
                    </div>
                  </div>
                )}
                <div className={classNames("grid grid-cols-2 gap-4 pt-16", {})}>
                  <div className="space-y-2">
                    <div className="w-full border-b-2 border-gray-400"></div>
                    <p className="text-[#808080] text-[8px] text-right uppercase">
                      {get(answers, "reports.signature_text", "")}
                    </p>
                  </div>
                </div>
              </section>
            </td>
          </tr>
        </tbody>
        <tfoot className="h-32">
          <tr>
            <td>
              <Disclaimer pageContent={pageContent} answers={answers} />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default FreeTextReport;
