import { useState, useEffect } from "react";

import ConfigForm from "./ConfigForm";
import ConfigList from "./ConfigList";
import ConfigDetail from "./ConfigDetail";
import ConfigCompare from "./ConfigCompare";

const WorkspaceAIConfig = (props) => {
  const { setShowBigModal, onClose } = props;
  const [configState, setConfigState] = useState("list");
  const [configId, setConfigId] = useState(null);
  const [configMeta, setConfigMeta] = useState({});
  const [confidNames, setConfigNames] = useState([]);

  const changeConfigState = (configState, configId = null) => {
    setConfigId(configId);
    setConfigState(configState);
  };

  return (
    <>
      {configState === "list" ? (
        <ConfigList
          changeConfigState={changeConfigState}
          setConfigNames={setConfigNames}
          setConfigMeta={setConfigMeta}
        />
      ) : configState === "form" ? (
        <ConfigForm
          configMeta={configMeta}
          setShowBigModal={setShowBigModal}
          onClose={onClose}
        />
      ) : configState === "detail" ? (
        <ConfigDetail
          configMeta={configMeta}
          onClose={onClose}
          changeConfigState={changeConfigState}
        />
      ) : configState === "compare" ? (
        <ConfigCompare
          configMeta={configMeta}
          onClose={onClose}
          changeConfigState={changeConfigState}
          configList={confidNames}
        />
      ) : null}
    </>
  );
};

export default WorkspaceAIConfig;
