import React, { useEffect, useState } from "react";
import { PlusIcon, CheckIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import useGetWorkspaceConfigList from "./hooks/useGetWorkspaceConfigList";
import useSetWorkspaceConfig from "./hooks/useSetWorkspaceConfig";
import Toast from "../Toast";
import { get } from "lodash";
import { useStoreState } from "pullstate";
import Store from "../../utils/Store";
import WorkspaceModal from "./WorkspaceModal";

export const ConfigPreview = ({
  answers,
  name,
  setConfigMeta,
  changeConfigState,
  configId,
  editDraft,
}) => {
  const setWorkSpaceConfig = useSetWorkspaceConfig();
  const [showWorkspaceModal, setShowWorkspaceModal] = useState(false);
  const getValue = (answers, valueKey) => {
    const allKeyValues = get(answers, valueKey, {});
    const filteredValues = Object.keys(allKeyValues).filter((key) => allKeyValues[key]);
    return filteredValues.length ? filteredValues.join(" , ") : "NA";
  };

  const getFindingsValue = (answers, valueKey) => {
    const allKeyValues = get(answers, valueKey, {});
    const filteredKeys = Object.keys(allKeyValues).filter((key) => allKeyValues[key]);
    const filteredValues = filteredKeys.map(finding => allKeyValues[finding]['label'])
    return filteredValues.length ? filteredValues.join(" , ") : "NA";
  };

  const getPrimaryReasonIndex = (answers) => {
    const primaryReason = get(answers, "model_config.primary_reason", null);
    if (primaryReason === "TB and other screening programs") {
      return 0;
    }
    if (primaryReason === "General X-ray reporting Assistance") {
      return 1;
    }
    if (primaryReason === "Notify or rule out specific findings on a Chest Xray") {
      return 2;
    }
    return 3;
  };

  const getConfigKeys = (answers) => {
    const questionConfigs = [
      {
        second_question: "model_config.primary_focus",
      },
      {
        second_question: "model_config.tat_reduction",
      },
      {
        second_question: null,
      },
      {},
    ];
    return questionConfigs[getPrimaryReasonIndex(answers)];
  };

  const { userInfo } = useStoreState(Store, (s) => ({
    userInfo: s.userInfo,
  }));
  const sources = get(userInfo, ["upload_sources"], []);
  const listSource = sources.map((src) => {
    return {
      label: src["name"],
      value: src["id"],
      uuid: src["uuid"],
    };
  });
  const default_workspace = listSource.find(
    (item) => item.value === userInfo?.default_workspace
  );

  const assignConfigToSource = (value) => {
    setWorkSpaceConfig
      .mutateAsync({
        name: name,
        workspace_id: value,
      })
      .then(() => {
        setShowWorkspaceModal(false);
        Toast.success("Config Assigned to Workspace Successfully");
      })
      .catch(() => {
        Toast.error("Unable to Assign config. Please try again.");
      });
  };

  const questionKeys = getConfigKeys(answers);
  return (
    <div className="bg-gray-100 dark:bg-gray-900 rounded-6 my-4 py-2 text-left">
      <div className="flex px-4 py-2 justify-between">
        <div className="">
          <span className="text-gray-600 dark:text-gray-400">AI CONFIG : </span>
          {name ? name : "NA"}
        </div>
        <div className="flex">
          {editDraft ? (
            <>
              <button
                className="flex text-teal-400 text-md mr-2 p-2 hover:underline hover:text-teal-300"
                onClick={() => {
                  setConfigMeta({
                    id: configId,
                    answers: answers,
                    name: name,
                  });
                  changeConfigState("detail");
                }}
              >
                Show JSON
              </button>
              <button
                className="flex p-2 mr-2 text-md border border-gray-600 rounded "
                onClick={() => setShowWorkspaceModal(true)}
              >
                Use Config
              </button>
              <button
                className="flex border border-gray-600 rounded text-md"
                onClick={() => {
                  setConfigMeta({
                    id: configId,
                    answers: answers,
                    name: name,
                    editing: true,
                  });
                  changeConfigState("form");
                }}
              >
                <div className="p-2 border-r border-gray-600">Draft</div>
                <PencilSquareIcon
                  className="cursor-pointer h-6 w-6 p-2"
                  aria-hidden="true"
                />
              </button>
              <button
                className="flex text-gray-100 rounded text-md ml-2 p-2 hover:underline hover:text-white bg-teal-500"
                onClick={() => {
                  setConfigMeta({
                    id: configId,
                    answers: answers,
                    name: name,
                  });
                  changeConfigState("compare");
                }}
              >
                Compare
              </button>
            </>
          ) : null}
        </div>
      </div>
      <div className="flex text-teal-300 text-md p-4 border-gray-600 border-b">
        <div className="pr-5 border-gray-600 border-r">
          Organization: {getValue(answers, "general.organization")}
        </div>
        <div className="px-5 border-gray-600 border-r">
          Scan Source: {getValue(answers, "general.careArea")}
        </div>
        <div className="pl-5">
          Primary users: {getValue(answers, "general.primaryUsers")}
        </div>
      </div>
      <div className="px-4 py-2 border-gray-600 border-b">
        <div className="py-2 flex items-center font-light">
          <CheckIcon className="h-5 w-5 mr-3" />
          {get(answers, "model_config.primary_reason", "NA")}
        </div>
        {questionKeys["second_question"] ? (
          <div className="py-2 flex items-center font-light">
            <CheckIcon className="h-5 w-5 mr-3" />
            {get(answers, questionKeys["second_question"], "NA")}
          </div>
        ) : null}
      </div>
      <div className="px-4 py-2">
        <div className="font-medium">Customisation to the config :</div>
        <div className="py-2 font-light">
          <div>Additional findings:</div>
          <div className="text-gray-600 dark:text-gray-400">
            {getFindingsValue(answers, "model_config.findings_for_notifying_thresholds")}
          </div>
        </div>
        <div className="py-2 font-light">
          <div>Findings with custom thresholds:</div>
          <div className="text-gray-600 dark:text-gray-400">
            {getFindingsValue(answers, "model_config.findings_for_ignoring_thresholds")}
          </div>
        </div>
      </div>
      <WorkspaceModal
        isOpen={showWorkspaceModal}
        onClose={() => setShowWorkspaceModal(false)}
        onSave={(val) => {
          assignConfigToSource(val);
        }}
      />
    </div>
  );
};

const ConfigList = ({ changeConfigState, setConfigMeta, setConfigNames }) => {
  const { data, isLoading } = useGetWorkspaceConfigList();
  const configList = get(data, "ai_flows", {});

  useEffect(() => {
    setConfigNames(Object.keys(configList));
  }, [JSON.stringify(configList)]);

  return (
    <div className="p-4">
      <button
        className={
          "inline-flex border bg-gray-600 dark:border-teal-300 dark:hover:border-teal-800 dark:bg-transparent dark:hover:bg-teal-800 hover:bg-teal-800 w-full space-x-4 items-center justify-center text-base font-medium rounded-6 text-gray-100 py-4 px-4 !mb-4 sm:!mb-0"
        }
        onClick={() => changeConfigState("form")}
      >
        <PlusIcon className="block cursor-pointer h-6 w-6" aria-hidden="true" />
        <p>Add a New Config</p>
      </button>
      <div className="h-[30rem] overflow-y-auto my-4">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          Object.keys(configList).map((id) => {
            const config = configList[id];
            const name = get(config, "name", null);
            const answers = get(config, ["ai_flow"], {});
            return (
              <ConfigPreview
                key={id}
                name={name}
                answers={answers}
                changeConfigState={changeConfigState}
                setConfigMeta={setConfigMeta}
                configId={id}
                editDraft
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default ConfigList;
