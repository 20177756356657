import { useStoreState } from "pullstate";
import { useState } from "react";
import classNames from "classnames";
import WorkspaceAIConfig from ".";
import Store from "../../utils/Store";
import Modal from "../common/Modal";

const WorkspaceAIConfigModal = () => {
  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isWorkspaceAIConfigOpen,
  }));

  const onCloseModal = () => {
    Store.update((s) => {
      s.isWorkspaceAIConfigOpen = false;
    });
    setShowBigModal(false)
  };

  const [showBigModal, setShowBigModal] = useState(false);
  
  return (
    <Modal
      title="Workspace AI Config"
      onClose={onCloseModal}
      show={isVisible}
      className={`flex flex-col h-auto !max-h-[90%] overflow-auto ${
        showBigModal ? "lg:w-10/12" : "lg:w-7/12"
      }`}
    >
      <WorkspaceAIConfig isModal setShowBigModal={setShowBigModal} isVisible={isVisible} onClose={onCloseModal} />
    </Modal>
  );
};

export default WorkspaceAIConfigModal;