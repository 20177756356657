import { get } from "lodash";
import { ArrowLongLeftIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import useGetConfigDetails from "./hooks/useGetConfigDetails";
import Toast from "../Toast";

const ConfigDetail = (props) => {
  const { configMeta, changeConfigState } = props;
  const name = get(configMeta, "name", "");
  const configId = get(configMeta, "id", undefined);

  const { data, isLoading } = useGetConfigDetails(name);
  const configData = get(data, ["ai_flows", name]);
  const jsonstring = JSON.stringify(configData, null, 2);

  return (
    <div className="p-4">
      <div className="border-gray-600 border rounded-6 text-left">
        <div className="border-gray-600 border-b flex">
          <button
            className="px-4 py-3 flex items-center"
            onClick={() => {
              changeConfigState("list");
            }}
          >
            <ArrowLongLeftIcon className="block h-6 w-6" aria-hidden="true" />
            Back
          </button>
          <div className="px-4 py-3 border-gray-600 border-l">
            <span className="text-gray-600 dark:text-gray-400">AI CONFIG :</span>
            {name ? name : "NA"}
          </div>
          <button
            className="border-gray-600 border px-4 py-2 rounded text-md flex items-center self-center ml-auto mr-4"
            onClick={() => {
              navigator.clipboard.writeText(jsonstring);
              Toast.success("JSON copied successfully.");
            }}
          >
            <DocumentDuplicateIcon className="block h-4 w-4" aria-hidden="true" />
            Copy Json
          </button>
        </div>
        <div className="px-4 py-2">
          <div className="mb-2">
            <span className="text-gray-600 dark:text-gray-400">JSON : </span>
          </div>
          <div className="h-[30rem] overflow-y-auto px-4 py-2 bg-gray-100 dark:bg-gray-900 rounded-6">
            {isLoading ? <div>Loading...</div> : <pre>{jsonstring}</pre>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigDetail;
