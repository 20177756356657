import { useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useGetConfigDetails = (name, options = {}) => {
  return useQuery(
    [name],
    () => {
      return servicesUtils.getService(`/profile/aiflows`, {
        fetch_all_ai_configs: false,
        name: name,
      });
    },
    options
  );
};

export default useGetConfigDetails;
