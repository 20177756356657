import PropTypes from "prop-types";
import Modal from "../common/Modal";
import {isEmpty} from "lodash";
import Checkbox from "../common/Checkbox";


const FindingsModal = ({
  modelTitle,
  allFindings,
  setAllFindings,
  selectedFindings,
  activeCategory,
  onChange,
  isVisible,
  onCloseModal,
  questionKey,
  changeAnswer,
  defaultFindings,
  exemptedFindings,
  suggestedThreshold
}) => {

  const configKeys = ["sensitive", "balanced", "specific", "rare"];

  const handleThresholdChange = (finding, newThreshold, findingLabel, questionKey, defaultFindings) => {
    setAllFindings(currentFindings => {
      return currentFindings.map(findingDict => {
        if (findingDict.tag === finding) {
          return { ...findingDict, threshold: newThreshold };
        }
        return findingDict;
      });
    });

    if (defaultFindings[finding]) {
      changeAnswer(`model_config.default_findings_thresholds`, {
        ...defaultFindings,
        [finding]: {
          'threshold': newThreshold,
          'modified': true,
          'label': findingLabel
        },
      })
    }
    else {
      changeAnswer(`${questionKey}_thresholds`, {
        ...selectedFindings,
        [finding]: {
          'threshold': newThreshold,
          'modified': true,
          'label': findingLabel
        }
      })
    }
  };
  const handleCheckBoxChange = (checkBoxSelected, threshold, finding, findingLabel, dependentFindings, defaultFindings) => {
    if (defaultFindings[finding]) {
      onChange(checkBoxSelected, finding, threshold, findingLabel, 'default_findings', dependentFindings, defaultFindings)
    }
    else {
      onChange(checkBoxSelected, finding, threshold, findingLabel, questionKey, dependentFindings, defaultFindings);
    }
  }
  return (
    <Modal
      title={modelTitle}
      onClose={onCloseModal}
      show={isVisible}
      bgOpacity
      className="flex flex-col w-full sm:w-7/12 h-[88%] lg:w-6/12 xl:w-5/12 sm:!h-auto sm:!max-h-[80%] overflow-hidden"
    >
      <div className="p-6 pb-8 overflow-y-auto scrollbar">
        <div className="text-left text-md">
          Suggested threshold : {suggestedThreshold}
        </div>
        <div>
          <div className="flex flex-col">
            <div className="flex flex-wrap">
              <div className="py-4">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="w-2/5 font-normal text-left text-gray-600 dark:text-gray-400">
                        ALL FINDINGS
                      </th>
                      {configKeys.map((key, i) => (
                        <th
                          key={key}
                          className="p-2 px-5 capitalize text-left font-normal text-gray-600 dark:text-gray-400"
                        >
                          {key}
                        </th>
                      ))}
                    </tr >
                  </thead >
                  <tbody>
                    {!isEmpty(allFindings) &&
                      allFindings.map((findingDict) => {
                        const finding = findingDict['tag']
                        const findingLabel = findingDict['label']
                        const isSelected = !isEmpty(selectedFindings && selectedFindings[finding] || defaultFindings && defaultFindings[finding])
                        const dependentFindings = findingDict['dependencies']
                        const infoMessage = findingDict['info_message']
                        const threshold = findingDict['threshold'] || 'rare'
                        return (
                          <tr key={finding}>
                            <div>
                              <Checkbox
                                key={finding}
                                id={finding}
                                name={"aiFind"}
                                disabled={defaultFindings[finding]}
                                infoMessage={infoMessage}
                                className="w-full my-2 pr-2"
                                checked={isSelected || defaultFindings[finding]}
                                onChange={(val) => handleCheckBoxChange(val, threshold, finding, findingLabel, dependentFindings, defaultFindings)}
                                label={findingLabel}
                              />
                            </div>
                            {configKeys.map((key) => {
                              return (<td key={key} className="p-2 text-center">
                                <input
                                  key={`${finding}-${key}`}
                                  id={`${finding}-radio-${key}`}
                                  type="radio"
                                  disabled={!isSelected}
                                  checked={isSelected && key === threshold }
                                  onChange={() => handleThresholdChange(finding, key, findingLabel, questionKey, defaultFindings)}
                                  className="h-5 w-5 dark:border-gray-300 text-teal-400 bg-transparent cursor-pointer"
                                />
                              </td>
                              )
                            })}
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal >
  );
};

FindingsModal.defaultProps = {
  onChange: () => { },
};

FindingsModal.propTypes = {
  findings: PropTypes.object,
  onChange: PropTypes.func,
};

export default FindingsModal;
