import { Listbox } from "@headlessui/react";
import { get, isEmpty } from "lodash";
import classNames from "classnames";

const DropdownMenu = ({
  placeholder,
  options,
  onChange,
  selectedOptions,
  labelMappings,
  tagsList,
  category,
  disabled,
}) => {
  return (
    <Listbox
      disabled={disabled}
      as={"div"}
      value={selectedOptions}
      onChange={(values) => onChange(values)}
      multiple
      className={classNames("relative", { "opacity-50": disabled })}
    >
      <Listbox.Button
        className={
          "relative border ui-open:border-b-gray-100 border-gray-600 dark:ui-open:bg-gray-900 rounded-6 ui-open:rounded-b-none w-full bg-transparent flex items-center px-5 py-3 pr-7"
        }
      >
        <span className="sm:text-[12px] truncate">
          {isEmpty(selectedOptions)
            ? placeholder
            : selectedOptions
                .map((opt_) => {
                  let opt;
                  if (category === "symptoms" || category === "risk_groups") {
                    const tags = get(tagsList, ["qxr", category, category], []);
                    tags.map((tag) => {
                      if (tag.id === opt_) opt = tag.choice;
                    });
                  } else {
                    opt = opt_;
                  }
                  return labelMappings[opt] || opt;
                })
                .join(", ")}
        </span>
        <svg
          className="ml-auto absolute top-1/2 -translate-y-1/2 right-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 9L12 15L6 9"
            stroke="#474F58"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Listbox.Button>
      <Listbox.Options
        className={
          "absolute top-full z-10 w-full py-3 space-y-2 border border-t-0 border-gray-600 bg-gray-100 dark:bg-gray-800 rounded-6 rounded-t-none overflow-hidden max-h-[200px] overflow-y-auto scrollbar-light"
        }
      >
        {options.map((opt_) => {
          let opt;
          if (typeof opt_ === "object") {
            opt = opt_.choice;
          } else {
            opt = opt_;
          }

          return (
            <Listbox.Option
              key={opt}
              value={typeof opt_ === "object" ? opt_.id : opt}
              as={"div"}
              className="cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 px-3"
            >
              <div className="flex items-center space-x-3">
                <span className="flex items-center">
                  <svg
                    className="hidden ui-selected:inline-block"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 1C1.89688 1 1 1.89688 1 3V13C1 14.1031 1.89688 15 3 15H13C14.1031 15 15 14.1031 15 13V3C15 1.89688 14.1031 1 13 1H3ZM11.5313 6.53125L7.53125 10.5312C7.2375 10.825 6.7625 10.825 6.47188 10.5312L4.47188 8.53125C4.17813 8.2375 4.17813 7.7625 4.47188 7.47188C4.76563 7.18125 5.24063 7.17813 5.53125 7.47188L7 8.94063L10.4688 5.46875C10.7625 5.175 11.2375 5.175 11.5281 5.46875C11.8188 5.7625 11.8219 6.2375 11.5281 6.52813L11.5313 6.53125Z"
                      fill="#00A9A7"
                    />
                    <path d="M11.5313 6.53125L7.53125 10.5312C7.2375 10.825 6.7625 10.825 6.47188 10.5312L4.47188 8.53125C4.17813 8.2375 4.17813 7.7625 4.47188 7.47188C4.76563 7.18125 5.24063 7.17813 5.53125 7.47188L7 8.94063L10.4688 5.46875C10.7625 5.175 11.2375 5.175 11.5281 5.46875C11.8188 5.7625 11.8219 6.2375 11.5281 6.52813L11.5313 6.53125Z" />
                  </svg>
                  <svg
                    className="inline-block ui-selected:hidden"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 3C1 1.89531 1.89531 1 3 1H13C14.1031 1 15 1.89531 15 3V13C15 14.1031 14.1031 15 13 15H3C1.89531 15 1 14.1031 1 13V3ZM2.5 3V13C2.5 13.275 2.72375 13.5 3 13.5H13C13.275 13.5 13.5 13.275 13.5 13V3C13.5 2.72375 13.275 2.5 13 2.5H3C2.72375 2.5 2.5 2.72375 2.5 3Z"
                      fill="#9EA3A8"
                    />
                  </svg>
                </span>

                <span>{labelMappings[opt] || opt}</span>
              </div>
            </Listbox.Option>
          );
        })}
      </Listbox.Options>
    </Listbox>
  );
};

export default DropdownMenu;
