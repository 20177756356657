import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useSetWorkspaceConfig = () => {
  return useMutation((params) =>
    servicesUtils.postService("/profile/aiflows/workspace", params)
  );
};

export default useSetWorkspaceConfig;
