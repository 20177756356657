import servicesUtils from "../../../utils/servicesUtils";

const useCompareConfigs = (params) => {
  return new Promise((resolve, reject) => {
    servicesUtils.postService("/profile/aiflows/validate", params).then((res) => {
      return resolve(res);
    }).catch((err) => {
      return reject(err.response.data);
    });
  });
};

export default useCompareConfigs;
