import Toggle from "../common/Toggle";
import Checkbox from "../common/Checkbox";
import TextareaInput from "../FormComponents/TextareaInput";
import DropdownMenu from "../FormComponents/DropdownMenu";
import { get } from "lodash";
import { useState } from "react";
import classNames from "classnames";
import { allFindings } from "./FindingsModal";
import FINDINGS from "../NotificationSettings/findingsLabels";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

const Collapsible = (props) => {
  const { renderTitle, children } = props;
  const [open, setOpen] = useState(false);
  const handleFilterOpening = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div
      className={classNames({
        "bg-gray-100 dark:bg-gray-900": open,
      })}
    >
      <div className="cursor-pointer" onClick={handleFilterOpening}>
        {renderTitle(open)}
      </div>
      <div>{open && children}</div>
    </div>
  );
};
const Sidebar = (props) => {
  const { step, answers, changeAnswer } = props;

  const sidebarHiddenTags = [
    ...Object.keys(get(answers, "model_config.default_findings_thresholds", {})),
    ...Object.keys(get(answers, "model_config.findings_for_ignoring_thresholds", {})),
    ...Object.keys(get(answers, "model_config.findings_for_notifying_thresholds", {}))
  ]
  
  const enableTubeRendering = ["breathingtube", "gustictube"].some((key) => {
    return Object.keys(get(answers, "model_config.findings_for_notifying_thresholds", {}))
      .includes(key) || Object.keys(get(answers, "model_config.findings_for_ignoring_thresholds", {}))
        .includes(key) || Object.keys(get(answers, "model_config.default_findings_thresholds", {})).includes(key)
  })

  const enableCTRLineRendering = ["heart", "cardiomegaly"].some((key) => {
    return Object.keys(get(answers, "model_config.findings_for_notifying_thresholds", {}))
      .includes(key) || Object.keys(get(answers, "model_config.findings_for_ignoring_thresholds", {}))
        .includes(key) || Object.keys(get(answers, "model_config.default_findings_thresholds", {})).includes(key)
  })

  if (step === 2) {
    return (
      <div className="w-[20%] border-l border-gray-700">
        <div className="flex items-center p-4 border-b border-gray-700">
          Secondary Capture
        </div>
        <div className="flex items-center p-4 border-b border-gray-700">
          <Checkbox
            id="sc-new-enabler"
            checked={get(answers, "sc.output_service", "") === "node_api"}
            onChange={(val) =>
              val
                ? changeAnswer("sc.output_service", "node_api")
                : changeAnswer("sc.output_service", "python_api")
            }
            label={"New SC"}
          />
        </div>
        <div className="flex items-center p-4 border-b border-gray-700">
          <Checkbox
            id="sc-header-enabler"
            checked={get(answers, "sc.cxr_sc_with_topbar", false)}
            onChange={(val) => changeAnswer("sc.cxr_sc_with_topbar", val)}
            label={"Topbar"}
          />
        </div>
        <div className="text-left border-b border-gray-700">
          <Collapsible
            renderTitle={(isOpen) => (
              <div className="flex items-center py-4 px-2 justify-between">
                <div>
                  <FontAwesomeIcon size="sm" icon={isOpen ? faChevronUp : faChevronDown} />
                  <span className="pl-2">Sidebar</span>
                </div>
                <Toggle
                  value={get(answers, "sc.cxr_sc_with_sidebar", false)}
                  onChange={(val) => changeAnswer("sc.cxr_sc_with_sidebar", val)}
                />
              </div>
            )}
          >
            <div className="p-4">
              Sidebar Hidden Tags
              <div className="py-3">
                <DropdownMenu
                  placeholder={"Select findings"}
                  selectedOptions={get(answers, "sc.sidebar_hidden_tags")}
                  onChange={(val) => changeAnswer("sc.sidebar_hidden_tags", val)}
                  options={sidebarHiddenTags}
                  labelMappings={FINDINGS}
                />
              </div>
            </div>
          </Collapsible>
        </div>
        <div className="text-left border-b border-gray-700">
          <Collapsible
            renderTitle={(isOpen) => (
              <div className="flex items-center py-4 px-2 justify-between">
                <div>
                  <FontAwesomeIcon size="sm" icon={isOpen ? faChevronUp : faChevronDown} />
                  <span className="pl-2">Contours</span>
                </div>
                <Toggle
                  value={get(answers, "sc.cxr_sc_with_mask_borders", false)}
                  onChange={(val) => changeAnswer("sc.cxr_sc_with_mask_borders", val)}
                />
              </div>
            )}
          >
            <div className="p-4">
              <Checkbox
                id="sc-report-contour-labels-enabler"
                checked={get(answers, "sc.cxr_sc_with_mask_labels", false)}
                onChange={(val) => changeAnswer("sc.cxr_sc_with_mask_labels", val)}
                label={"Contour Labels"}
              />
            </div>
            <div className="p-4">
              <Checkbox
                id="sc-report-enabled-quantification"
                checked={get(answers, "sc.enable_quantification", false)}
                onChange={(val) => changeAnswer("sc.enable_quantification", val)}
                label={"Quantification Labels"}
              />
            </div>
            <div className="p-4">
              <Checkbox
                id="sc-report-enabled-tube-enabler"
                checked={enableTubeRendering || get(answers, "sc.enabled_tube_rendering", false)}
                onChange={(val) => changeAnswer("sc.enabled_tube_rendering", val)}
                label={"Breathing Tubes"}
              />
            </div>
            <div className="p-4">
              <Checkbox
                id="sc-report-enabled-ctr-line"
                checked={enableCTRLineRendering || get(answers, "sc.enable_ctr_line", false)}
                onChange={(val) => changeAnswer("sc.enable_ctr_line", val)}
                label={"CTR line"}
              />
            </div>
          </Collapsible>
        </div>
        <div className="text-left border-b border-gray-700">
          <Collapsible
            renderTitle={(isOpen) => (
              <div className="flex items-center py-4 px-2 justify-between">
                <div>
                  <FontAwesomeIcon size="sm" icon={isOpen ? faChevronUp : faChevronDown} />
                  <span className="pl-2">Bottombar</span>
                </div>
                <Toggle
                  value={get(answers, "sc.cxr_sc_with_bottombar", false)}
                  onChange={(val) => changeAnswer("sc.cxr_sc_with_bottombar", val)}
                />
              </div>
            )}
          >
            <div className="p-4">
              <Checkbox
                id="sc-report-bottom-version-enabler"
                checked={get(answers, "sc.enable_version_info", false)}
                onChange={(val) => changeAnswer("sc.enable_version_info", val)}
                label={"Version Info"}
              />
            </div>
            <div className="p-4">
              <TextareaInput
                label={"Text"}
                value={get(answers, "sc.cxr_sc_disclaimer", "")}
                onChange={(value) => changeAnswer("sc.cxr_sc_disclaimer", value)}
                placeholder="Enter label"
                labelClassName="bg-gray-100 dark:bg-gray-900"
              />
            </div>
          </Collapsible>
        </div>
      </div>
    );
  }
  if (step === 3) {
    return (
      <div className="w-[20%] border-l border-gray-700">
        <div className="flex items-center p-4 border-b border-gray-700">PDF Report</div>
        <div className="flex items-center p-4 border-b border-gray-700">
          <Checkbox
            id="pdf-new-report-enabler"
            checked={get(answers, "reports.output_service", "") === "node_api"}
            onChange={(val) =>
              val
                ? changeAnswer("reports.output_service", "node_api")
                : changeAnswer("reports.output_service", "python_api")
            }
            label={"New PDF Report"}
          />
        </div>
        <div className="text-left border-b border-gray-700">
          <Collapsible renderTitle={(isOpen) => <div className="p-4">First Page</div>}>
            <div className="p-4">
              <TextareaInput
                label={"Signature Text"}
                value={get(answers, "reports.signature_text", "")}
                onChange={(value) => changeAnswer("reports.signature_text", value)}
                placeholder="Enter Signature text"
                labelClassName="bg-gray-100 dark:bg-gray-900"
              />
            </div>
            <div className="p-4">
              <TextareaInput
                label={"Disclaimer Text"}
                value={get(answers, "reports.bottom_disclaimer_text", "")}
                onChange={(value) =>
                  changeAnswer("reports.bottom_disclaimer_text", value)
                }
                placeholder="Enter Disclaimer text"
                labelClassName="bg-gray-100 dark:bg-gray-900"
              />
            </div>
          </Collapsible>
        </div>
        <div className="flex items-center p-4 border-b border-gray-700 justify-between">
          <div className="">Second Page</div>
          <Toggle
            value={get(answers, "reports.enabled_sc_thumbnail", false)}
            onChange={(val) => changeAnswer("reports.enabled_sc_thumbnail", val)}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default Sidebar;
