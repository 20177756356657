import servicesUtils from "../../../utils/servicesUtils";

const getTagsDetails = (payload) => {
  return new Promise((resolve, reject) => {
    servicesUtils.postService("/profile/aiflows/update", payload).then((res) => {
      return resolve(res);
    })
    .catch((err) => {reject(err.response.data)});
  });
};

export default getTagsDetails;
