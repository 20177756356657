import { get, isEmpty } from "lodash";
import Image from "next/image";
import Tooltip from "../Tooltip";
import classNames from "classnames";

const SelectedTags = (props) => {

    const { header, answers, answerkey } = props;
    const tags = get(answers, answerkey, {})
    return (
        <div id={answerkey}>
            <div className="py-3 pl-4">{header}</div>
            <hr className="my-1 border-t border-gray-700" />
            <div className={classNames("overflow-y-scroll max-h-40")}
            >
                {Object.keys(tags).map(tag => {
                    return (
                        <span
                            key={tag}
                            className={classNames(
                                "inline-flex items-center px-2.5 py-2 rounded-full text-gray-900 mr-2 my-2 relative border-gray-200 border bg-gray-700 mx-2 ml-2",
                            )}
                        >
                            <Tooltip
                                content={tags[tag]['modified'] && header == 'Default' ? 'Edited default threshold' : ''}
                                position="bottom-left"
                            >
                                <span
                                    className={classNames(
                                        "text-md text-gray-900 dark:text-gray-100 flex items-center space-x-2"
                                    )}
                                >
                                    <span className="pr-2">{tags[tag]['label']}</span>
                                    {tags[tag]['modified'] && header == 'Default' &&
                                        <Image
                                            src="/assets/icons/Info.svg"
                                            alt="emergency"
                                            width="16"
                                            height="16"
                                        />
                                    }
                                </span>

                            </Tooltip>
                        </span>
                    )
                })}
            </div >
        </div >
    );
};

export default SelectedTags;
