import React from 'react'
import _ from 'lodash'

function Header({ pageContent }) {
  const headers = _.get(pageContent, ['headers'])
  const patient_data = _.get(pageContent, ['patient_data'])

  return (
    <header className="grid grid-cols-2 gap-4 break-words border-b-[3px] border-gray-400 py-8">
      <div className="flex flex-col justify-between">
        <h1 className="font-bold text-2xl w-5/6 uppercase">
          {headers.report_top_header}
        </h1>
        <div>
          <p className="text-[8px] text-[#808080] uppercase">
            {headers.header_location}
          </p>
          <p className="text-xs">{patient_data.institution}</p>
        </div>
      </div>
      <div className="space-y-6">
        <div>
          <p className="text-[8px] text-[#808080] uppercase">
            {headers.header_patient_name}
          </p>
          <p className="text-xs">{patient_data.patient_name}</p>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <p className="text-[8px] text-[#808080] uppercase">
              {headers.header_examination_date}
            </p>
            <p className="text-xs">{patient_data.study_date}</p>
          </div>
          <div>
            <p className="text-[8px] text-[#808080] uppercase">
              {headers.header_patient_code}
            </p>
            <p className="text-xs">{patient_data.patient_id}</p>
          </div>
        </div>
        <div>
          <p className="text-[8px] text-[#808080] uppercase">
            {headers.header_age} / {headers.header_gender}
          </p>
          <p className="text-xs">
            {patient_data.patient_age} / {patient_data.patient_gender}
          </p>
        </div>
      </div>
    </header>
  );
}

export default Header