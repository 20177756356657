import React from "react";
import _ from "lodash";

function Normal({ pageContent }) {
  const headers = _.get(pageContent, ["headers"]);
  const report = _.get(pageContent, ["report"]);
  const rootDirPath = _.get(pageContent, ["files_path", "root_dir_path"], "");
  const thumbnailPath = `${rootDirPath ? `/${rootDirPath}/` : "/"}${_.get(
    pageContent,
    ["files_path", "thumbnail_path"]
  )}`;

  return (
    <section className="py-8 space-y-4 justify-between flex flex-col">
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-4">
          <div className="text-sm">
            <h2 className="font-semibold">{_.startCase(_.toLower(headers.header_impression))}:</h2>
            <p>{report?.impression}</p>
          </div>
          <div
            className={`border border-gray-600 mt-2 divide-y divide-gray-600`}
          >
            <div className="grid items-center">
              <p className="text-sm px-2 border-r font-semibold">
                {_.startCase(_.toLower(headers.header_findings))}:
              </p>
            </div>
            <div className="grid items-center">
              <pre className="text-xs px-2 pt-2">
                <div
                  className="dangerous-text"
                  dangerouslySetInnerHTML={{ __html: report?.findings }}
                />
              </pre>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="text-sm invisible mb-2">
            <h2 className="font-semibold">{headers.header_impression}:</h2>
            <p>{report?.impression}</p>
          </div>
          <img
            src={thumbnailPath}
            alt={thumbnailPath}
            className="w-full h-56 rounded-md object-contain"
          />
        </div>
      </div>
    </section>
  );
}

export default Normal;
