import { useEffect } from "react";
import AutomatedFindingsModal from "./AutomatedFindingsModal";
import _, { isArray, unset } from "lodash";


const FindingsModal = (props) => {
  const {
    modelTitle,
    findings,
    changeAnswer,
    questionKey,
    showThresholds,
    disabled,
    exemptedFindings,
    defaultFindings,
    isOpen,
    setIsOpen,
    suggestedThreshold,
    allFindings,
    setAllFindings
  } = props;
  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const findingsToShow = (newFindingsArray, exemptedFindings) => {
    // Filter out findings that are in exemptedFindings before mapping
    const filteredFindingsArray = newFindingsArray?.filter(findingObj =>
      !(exemptedFindings && exemptedFindings[findingObj.tag])
    );

    return filteredFindingsArray?.map(findingObj => {
      // Create a copy of the finding object
      let modifiedFinding = { ...findingObj };

      // Check if any of the finding's dependencies are in exemptedFindings
      if (findingObj.dependencies && findingObj.dependencies.length > 0) {
        // Update the default property based on dependencies exemption
        const isExempted = findingObj.dependencies.some(dependency => exemptedFindings && exemptedFindings[dependency]);
        modifiedFinding.default = !isExempted;
      }

      return modifiedFinding;
    });
  };

  return (
    <>
      <button
        onClick={onOpenModal}
        disabled={disabled}
        className="cursor-pointer text-teal-400 hover:text-teal-300"
      >
        Select
      </button>
      <AutomatedFindingsModal
        modelTitle={modelTitle}
        onCloseModal={onCloseModal}
        isVisible={isOpen}
        allFindings={findingsToShow(allFindings, exemptedFindings)}
        setAllFindings={setAllFindings}
        changeAnswer={changeAnswer}
        questionKey={questionKey}
        defaultFindings={defaultFindings}
        showThresholds={showThresholds}
        selectedFindings={findings}
        exemptedFindings={exemptedFindings}
        suggestedThreshold={suggestedThreshold}
        onChange={(isSelected, finding, val, findingLabel, qKey, dependentFindings, defaultFindings) => {
          let updatedFindings = { ...findings };

          if (!isSelected) {
            unset(updatedFindings, finding);
            dependentFindings?.forEach(depFinding => {
              if (!defaultFindings[depFinding.tag]) {
                unset(updatedFindings, depFinding.tag);
              }
            });
          } else {
            const formattedDepFindings = {};
            dependentFindings?.forEach(depFinding => {
              if (!defaultFindings[depFinding.tag]) {
                formattedDepFindings[depFinding.tag] = {
                  'threshold': depFinding.threshold,
                  'modified': false,
                  'label': depFinding.label
                };
              }
            });
            updatedFindings = {
              ...updatedFindings,
              ...formattedDepFindings,
              [finding]: {
                'threshold': val,
                'modified': false,
                'label': findingLabel
              },
            };
          }
          changeAnswer(`${qKey}_thresholds`, updatedFindings);
        }}
      />
    </>
  );
};

export default FindingsModal;

