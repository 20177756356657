import React from "react";

const TooltipArrow = ({ arrowPosition }) => {

    return (
        <div className="absolute w-full" style={arrowPosition}>
            <svg
                className="mx-auto h-4 text-gray-700 stroke-gray-800"
                viewBox="0 0 100 100"
            >
                <polygon fill="currentColor" points="50 15, 100 100, 0 100" />
            </svg>
        </div>
    );
};

export default TooltipArrow;