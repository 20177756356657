import { get } from "lodash";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import FINDINGS from "../NotificationSettings/findingsLabels";
import Tooltip from "../Tooltip";
import Button from "../common/Button";
import TextInput from "../FormComponents/TextInput";
import compareConfigs from "./hooks/useCompareConfigs";
import SelectInput from "../FormComponents/SelectInput";
import useWorkspacesData from "../WorkspaceManagementModal/services/useWorkspaceData";
import Image from "next/image";

const Stats = ({ title, normal, abnormal }) => {
  return (
    <div>
      <div className="text-md py-2 text-red-400 font-medium text-left">{title}</div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1 rounded bg-gray-800 py-3 px-4 text-right">
          <div className="text-lg pb-2">{normal}</div>
          <div className="font-light text-teal-400">Normal scan </div>
        </div>
        <div className="col-span-1 rounded bg-gray-800 py-3 px-4 text-right">
          <div className="text-lg pb-2">{abnormal}</div>
          <div className="font-light text-teal-400">Abnormal scan </div>
        </div>
      </div>
    </div>
  );
};

const ConfigCompare = (props) => {
  const { changeConfigState, configMeta, configList } = props;
  const [configName, setConfigName] = useState("");
  const [configJson, setConfigJson] = useState("");
  const [compareData, setCompareData] = useState({});
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");


  const prevelanceData = get(compareData, "prevelance_report", {});
  const extraFindingsNew = get(compareData, "mismatch_tags.extra_tags_in_new_config", []);
  const extraFindingsOld = get(compareData, "mismatch_tags.extra_tags_in_old_config", []);
  const findingsData = get(prevelanceData, "tag_wise_details", {});
  const findings = Object.keys(findingsData);


  const { data: workspaceData } = useWorkspacesData(true);
  const ownedWorkspaces = get(workspaceData, ["owned_workspaces"], []);
  const workspaceOptions = ownedWorkspaces.map((w) => ({
    id: w.id,
    label: w.name,
    value: w.id,
  }));

  // Need to remove the current config from the list of configs to compare with
  const _configList = configList.filter((val) => val !== get(configMeta, "name", ""));

  useEffect(() => {
    const _selectedWorkspaceId = get(workspaceOptions, [0, "id"], 0);
    setSelectedWorkspaceId(_selectedWorkspaceId);
    compareConfigs({
      ai_flow: get(configMeta, "name", ""),
      workspace_id: _selectedWorkspaceId,
      compare_with_aiflow: false,
      compare_with: '',
      old_config_to_compare: '',
    }).then((data) => setCompareData(data))
      .catch((err) => {setErrorMessage(err.message)});
  }, [workspaceData]);


  const handleCompareConfigs = () => {
    compareConfigs({
      ai_flow: get(configMeta, "name", ""),
      workspace_id: selectedWorkspaceId,
      compare_with_aiflow: configJson ? false : true,
      compare_with: configName,
      old_config_to_compare: configJson,
    }).then((data) => setCompareData(data))
    .catch((err) => {setErrorMessage(err.message)})
  };

  const roundPercentage = (num) => {
    const per = Math.round(num * 100) / 100;

    return isNaN(per) ? "--" : per;
  };

  return (
    <>
      <div className="bg-gray-900">
        <div className="px-5 py-4 flex items-center">
          <div className="py-3 pr-3 flex-1">
            <SelectInput
              options={_configList.map((val) => ({
                value: val,
                label: val,
              }))}
              value={configName}
              onChange={setConfigName}
              disabled={configJson?.length}
              className="!p-3 bg-gray-200 !text-gray-800"
              placeholder="Select Config"
              enableClear={true}
            />
          </div>
          <div className="py-3 pl-3 flex-1">
            <TextInput
              placeholder="Paste json file"
              onChange={(val) => setConfigJson(val)}
              disabled={configName?.length}
              name="workspaceName"
              id="workspaceName"
              wrapperClassName="!p-2 bg-gray-200 col-span-2 !text-gray-800"
            />
          </div>
          <Button
            isPrimary
            disabled={!configName && !configJson}
            onClick={() => handleCompareConfigs()}
            className="sm:mt-0 grow-1 ml-4 w-full sm:w-40 font-light"
          >
            Compare
          </Button>
        </div>
        <div className="grid grid-cols-2 gap-7 p-5 w-[85%]">
          <div className="col-span-1">
            <Stats
              title={`CURRENT: ${get(configMeta, "name", "")}`}
              normal={get(prevelanceData, ["nva_prevelance_new", "normal"], "-")}
              abnormal={get(prevelanceData, ["nva_prevelance_new", "abnormal"], "-")}
            />
          </div>
          <div className="col-span-1">
            <Stats
              title={configJson ? `REFERENCE: JSON` : `REFERENCE: ${configName}`}
              normal={get(prevelanceData, ["nva_prevelance_old", "normal"], "-")}
              abnormal={get(prevelanceData, ["nva_prevelance_old", "abnormal"], "-")}
            />
          </div>
        </div>
        <div className="pt-3 border-t border-gray-700">
          <div className="grid grid-cols-11 py-3">
            <div className="col-span-3 text-left font-light pl-5">All Findings</div>
            <div className="col-span-4 font-light">CURRENT</div>
            <div className="col-span-4 font-light">REFERENCE</div>
          </div>
          <div className="grid grid-cols-11 py-3">
            <div className="col-span-3" />
            <div className="col-span-2">THRESHOLD</div>
            <div className="col-span-2 flex items-center justify-center">
              PREVALENCE %
              {/* <Tooltip content={"Percentage of Abnormal Scans"} position={"top"}>
                <InformationCircleIcon className="h-5 w-5 ml-3" />
              </Tooltip> */}
            </div>
            <div className="col-span-2">THRESHOLD</div>
            <div className="col-span-2 flex items-center justify-center">PREVALENCE %</div>
          </div>
          <div className="h-[15rem] overflow-y-auto">
            {errorMessage ? <div className="flex justify-center mt-7">
              <Image
                src="/assets/icons/errorMark.svg"
                alt="errorMark"
                width="18"
                height="18"
              />
              <span className="ml-2">{errorMessage}</span>
            </div> :
              findings.map((finding) => {
                return (
                  <div
                    key={finding}
                    className="grid grid-cols-11 py-3 border-b border-gray-700"
                  >
                    <div
                      className={classNames(
                        "col-span-3 text-left font-light pl-5 flex items-center",
                        {
                          "text-yellow-400":
                            extraFindingsNew.includes(finding) ||
                            extraFindingsOld.includes(finding),
                        }
                      )}
                    >
                      <Tooltip
                        content={
                          extraFindingsNew.includes(finding)
                            ? "Not present in Referred config"
                            : extraFindingsOld.includes(finding)
                              ? "Not present in Current config"
                              : ""
                        }
                        className={classNames("mt-1", {
                          invisible: !(
                            extraFindingsNew.includes(finding) ||
                            extraFindingsOld.includes(finding)
                          ),
                        })}
                        position={"bottom-left"}
                      >
                        <Image
                          src="/assets/icons/exclamation.svg"
                          alt="exclamation"
                          width="16"
                          height="16"
                        />
                      </Tooltip>
                      <div className="ml-3 capitalize">
                        {get(findingsData, [finding, "label"], finding)}
                      </div>
                    </div>
                    <div className="col-span-2 font-light text-gray-400">
                      {get(findingsData, [finding, "new_threshold"], "-")}
                    </div>
                    <div className="col-span-2 font-light text-gray-400">
                      {roundPercentage(
                        get(
                          prevelanceData,
                          ["new_tag_wise_prevelance", finding, "presence_percentage"],
                          "-"
                        )
                      )}
                    </div>
                    <div className="col-span-2 font-light text-gray-400">
                      {get(findingsData, [finding, "old_threshold"], "-")}
                    </div>
                    <div className="col-span-2 font-light text-gray-400">
                      {roundPercentage(
                        get(
                          prevelanceData,
                          ["old_tag_wise_prevelance", finding, "presence_percentage"],
                          "-"
                        )
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="flex items-center flex-row justify-end p-4 border-t border-t-gray-600">
        <Button
          onClick={() => changeConfigState("list")}
          className="sm:mt-0 grow-1 ml-4 w-full sm:w-40 font-light"
        >
          Back
        </Button>
      </div>
    </>
  );
};

export default ConfigCompare;
