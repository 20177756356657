import React, { useEffect, useState } from "react";
import { get } from "lodash";
import Modal from "../common/Modal";
import useWorkspacesData from "../WorkspaceManagementModal/services/useWorkspaceData";
import SelectInput from "../FormComponents/SelectInput";
import Button from "../common/Button";

export const SelectWorkspace = ({
  isOpen,
  selectedWorkspaceId,
  setSelectedWorkspaceId,
}) => {
  const { data: workspaceData } = useWorkspacesData({ isOpen });
  const ownedWorkspaces = get(workspaceData, ["owned_workspaces"], []);
  const workspaceOptions = ownedWorkspaces.map((w) => ({
    id: w.id,
    label: w.name,
    value: w.id,
  }));

  useEffect(() => {
    const _selectedWorkspaceId = get(workspaceOptions, [0, "id"], 0);
    setSelectedWorkspaceId(_selectedWorkspaceId);
  }, [workspaceData]);

  return (
    <div className="p-4">
      <div className="flex items-center border border-gray-700 rounded-lg pl-3 py-1">
        <div className="text-base">Workspace:</div>
        <SelectInput
          options={workspaceOptions}
          value={selectedWorkspaceId}
          onChange={setSelectedWorkspaceId}
          disabled={selectedWorkspaceId && workspaceOptions?.length <= 1}
          className="w-full ml-3 border-none"
        />
      </div>
    </div>
  );
};

const WorkspaceModal = ({ isOpen, onClose, onSave }) => {
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(0);

  return (
    <Modal
      title={"Select Workspace"}
      onClose={onClose}
      show={isOpen}
      className="flex flex-col w-full sm:w-7/12 lg:w-6/12 xl:w-4/12 h-auto !max-h-[90%] overflow-hidden"
    >
      <SelectWorkspace
        isOpen={isOpen}
        selectedWorkspaceId={selectedWorkspaceId}
        setSelectedWorkspaceId={setSelectedWorkspaceId}
      />
      <div className="flex items-center flex-row justify-end p-4 border-t border-t-gray-600">
        <Button
          onClick={() => onSave(selectedWorkspaceId)}
          className="sm:mt-0 grow-1 ml-4 w-full sm:w-40 font-light"
        >
          Set Config
        </Button>
      </div>
    </Modal>
  );
};

export default WorkspaceModal;
