import React from "react";
import _ from "lodash";

function Disclaimer({ pageContent, answers }) {
  const disclaimerConfig = _.get(pageContent, ["disclaimer_config"]);
  const headers = _.get(pageContent, ["headers"]);
  return (
    <footer className="space-y-6 max-w-xl mx-auto">
      <div className="grid grid-cols-12 items-center gap-8">
        <div className="col-span-4">
          <img src={`/images/main-logo.svg`} alt="main-logo" className="w-5/6" />
        </div>
        <div className="text-[#808080] col-span-8 break-words">
          <p className="text-[12px]">{headers.header_disclaimer}: </p>
          <p className="text-[10px] leading-3">
            {_.get(answers, "reports.bottom_disclaimer_text", "")}
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Disclaimer;
