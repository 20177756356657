import { useState } from "react";
import Checkbox from "../common/Checkbox";
import SelectInput from "../FormComponents/SelectInput";
import FindingsModal from "./FindingsModal";
import { get, isEmpty } from "lodash";

const Question = (props) => {
  const { questionData, serialNumber, answer, exemptedFindings, findings, changeAnswer, allFindings, setAllFindings, defaultFindings} =
    props;
  const {
    modelTitle,
    question,
    options,
    type,
    key,
    header,
    canSelectFindings,
    suggestedThreshold
  } = questionData;

  const [isFindingsModalOpen, setIsFindingsModalOpen] = useState(false);
  const handleChange = (key, opt, clearKeys) => {
    if (opt === "Yes" && canSelectFindings) {
      setIsFindingsModalOpen(true);
    }
    changeAnswer(key, opt, clearKeys);
  };
  
  const renderOptions = () => {
    switch (type) {
      case "radio":
        return (
          <div className="ml-6 mt-5 pl-5 border-l border-gray-700">
            {options.map((opt, i) => (
              <div key={opt} className="mb-5 last:mb-0">
                <div className="flex items-center">
                  <input
                    id={`${question}-radio-${opt}`}
                    type="radio"
                    checked={opt === answer}
                    onChange={() => handleChange(key, opt, [`${key}_thresholds`])}
                    className="h-5 w-5 dark:border-gray-300 text-teal-400 bg-transparent cursor-pointer"
                  />
                  <label
                    htmlFor={`${question}-radio-${opt}`}
                    className="ml-3 dark:text-gray-200 cursor-pointer"
                  >
                    {opt}
                  </label>
                  
                  {i === 0 && canSelectFindings && (
                    <div className="ml-auto mr-6">
                      <FindingsModal
                        modelTitle={modelTitle}
                        disabled={opt !== answer}
                        findings={findings}
                        allFindings={allFindings}
                        setAllFindings={setAllFindings}
                        changeAnswer={changeAnswer}
                        exemptedFindings={exemptedFindings}
                        defaultFindings={defaultFindings}
                        questionKey={key}
                        isOpen={isFindingsModalOpen}
                        setIsOpen={setIsFindingsModalOpen}
                        suggestedThreshold={suggestedThreshold}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        );
      case "checkbox":
        return (
          <div className="ml-6 mt-5 pl-5 border-l border-gray-700">
            {options.map((opt) => (
              <div key={opt} className="flex items-center mb-5 last:mb-0">
                <Checkbox
                  id={`${question}-config-${opt}`}
                  checked={get(answer, opt, false)}
                  onChange={(val) => changeAnswer(key, { ...answer, [opt]: val })}
                  label={opt}
                />
              </div>
            ))}
          </div>
        );
      case "radio_dropdown":
        return (
          <div className="ml-6 mt-5">
            <SelectInput
              options={options.map((opt) => ({ label: opt, value: opt }))}
              value={answer}
              onChange={(val) => changeAnswer(key, val)}
              className="sm:w-1/2 bg-gray-200 dark:bg-gray-800 border-none"
            />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="mb-6 text-left">
      {header ? <div className="mb-4"> {header} </div> : null}
      <div className="flex">
        <span className="mr-5">{serialNumber}</span>
        {question}
      </div>
      {renderOptions()}
    </div>
  );
};

export default Question;