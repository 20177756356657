import { useState, useEffect, useRef } from "react";
import { get, isEmpty } from "lodash";

import Question from "./Question";
import getTagsDetails from "./hooks/getTagsDetails";
import SelectedTags from "./SelectedTags";
import Image from "next/image";

const configQuestion = {
  key: "model_config.primary_reason",
  type: "radio",
  question: "What is the primary application of qXR intended to be?",
  options: [
    "TB and other screening programs",
    "General X-ray reporting Assistance",
    "Notify or rule out specific findings on a Chest Xray",
  ],
};

const focusedFindingsQuestion = {
  key: "model_config.findings_for_notifying",
  dependentKey: "model_config.findings_for_ignoring_thresholds",
  type: "radio",
  question: "Are there other findings of focus for the screening program?",
  canSelectFindings: true,
  modelTitle: 'Select other findings of focus',
  suggestedThreshold: 'Sensitive, Balanced',
  showThresholds: true,
  options: ["Yes", "No"],
  tagSection: {
    header: 'Additional',
    answerkey: 'model_config.findings_for_notifying_thresholds'
  }
};

const ignoredFindingsQuestion = {
  key: "model_config.findings_for_ignoring",
  dependentKey: "model_config.findings_for_notifying_thresholds",
  type: "radio",
  question: `Are there specific findings you would prefer to ignore, except for when they are highly remarkable?`,
  canSelectFindings: true,
  showThresholds: true,
  suggestedThreshold: "Rare",
  modelTitle: 'Select findings to ignore except when highly remarkable',
  options: ["Yes", "No"],
  tagSection: {
    header: 'Only if highly remarkable',
    answerkey: 'model_config.findings_for_ignoring_thresholds',
  }
};

const firstSet = [
  focusedFindingsQuestion,
  ignoredFindingsQuestion,
];

const secondSetQuestions = [
  [{
    key: "model_config.primary_focus",
    type: "radio",
    question: "Which finding is of primary focus for the screening program?",
    options: ["Tubercolosis"],
    tagSection: {
      header: 'Default',
      answerkey: 'model_config.default_findings_thresholds'
    }
  }],
  [{
    key: "model_config.primary_focus",
    type: "radio",
    question: "What is more important to you in terms of TAT reduction?",
    options: [
      "Segregating normal scans with high confidence",
      "Assistance in reading & reporting abnormal scans",
    ],
    tagSection: {
      header: 'Default',
      answerkey: 'model_config.default_findings_thresholds'
    }
  }],
  [{
    key: "model_config.primary_focus",
    dependentKey: "model_config.findings_for_ignoring_thresholds",
    type: "radio",
    question:
      "Which specific findings you would want to be notified about or rule out in every study?",
    // canSelectFindings: false,
    showThresholds: true,
    options: ["Lung nodule"],
    tagSection: {
      header: 'Default',
      answerkey: 'model_config.default_findings_thresholds'
    }
  }]

]
const secondSet = [
  {
    key: "model_config.findings_for_notifying",
    dependentKey: "model_config.findings_for_ignoring_thresholds",
    type: "radio",
    header:
      "Apart from the default settings of qXR for comprehensive detection of abnormalities in a CXR -",
    question:
      "Are there specific findings you would want to set high sensitivity for? Avoid missing less remarkable cases.",
    canSelectFindings: true,
    suggestedThreshold: 'Sensitive, Balanced',
    modelTitle: 'Adjust thresholds as suggested',
    showThresholds: true,
    options: ["Yes", "No"],
    tagSection: {
      header: 'Notify or rule out',
      answerkey: "model_config.findings_for_notifying_thresholds",
    }
  },
  {
    key: "model_config.findings_for_ignoring",
    dependentKey: "model_config.findings_for_notifying_thresholds",
    type: "radio",
    question:
      "Are there specific findings you would prefer to ignore, except for when they are highly remarkable?",
    canSelectFindings: true,
    showThresholds: true,
    suggestedThreshold: "Rare",
    modelTitle: 'Adjust thresholds as suggested',
    options: ["Yes", "No"],
    tagSection: {
      header: 'Only if critical',
      answerkey: "model_config.findings_for_ignoring_thresholds",
    }

  },
];
const thirdSet = [
  {
    key: "model_config.findings_for_ignoring",
    dependentKey: "model_config.findings_for_notifying_thresholds",
    type: "radio",
    question:
      "Are there specific findings you would prefer to ignore, except for when they are highly remarkable?",
    canSelectFindings: true,
    showThresholds: true,
    suggestedThreshold: "Rare",
    modelTitle: 'Select findings to ignore except when highly remarkable',
    options: ["Yes", "No"],
    tagSection: {
      header: 'Only if critical',
      answerkey: "model_config.findings_for_ignoring_thresholds",
    }
  },
];

const questionSetDict = {
  "Tubercolosis": firstSet,
  "Segregating normal scans with high confidence": secondSet,
  "Assistance in reading & reporting abnormal scans": secondSet,
  "Lung nodule": thirdSet,
  "Heart Failure": thirdSet
}

const ConfigurationForm = (props) => {
  const { answers, changeAnswer, configMeta } = props;
  const [usecaseQuestions, setUsecaseQuestions] = useState([]);
  const [lastQuestions, setLastQuestions] = useState([]);
  const [error, setError] = useState('');
  const [allFindings, setAllFindings] = useState([]);

  const configAns = get(answers, configQuestion.key, null);
  const usecaseAns = get(answers, 'model_config.primary_focus', null);


  useEffect(() => {
    const { key, options } = configQuestion;
    if (configAns) {
      setUsecaseQuestions(
        secondSetQuestions.find((questions, i) => {
          if (options[i] === configAns) {
            return true;
          }
          return false;
        })
      )
    }
  }, [configAns]);


  useEffect(() => {
    if (usecaseAns) {
      getTagsDetails({ 'ai_flow': answers })
        .then((tagData) => {
          const _defaultFindingsDict = {}
          tagData.filter(tagDetails => tagDetails.default)
            .map((finding) => _defaultFindingsDict[finding['tag']] = { 'threshold': finding['threshold'], 'modified': false, 'label': finding['label'] })
          if (!configMeta.editing) {
            changeAnswer('model_config.default_findings_thresholds', _defaultFindingsDict);
          }
          setAllFindings(tagData)
        })
        .then(setLastQuestions(questionSetDict[usecaseAns]))
        .catch((err) => { setError(err.message) })
    }
  }, [usecaseAns]);

  const handleSetNextQuestions = () => {
    const { key, options } = configQuestion;
    const configAns = get(answers, key, null);
    if (configAns) {
      setUsecaseQuestions(
        secondSetQuestions.find((questions, i) => {
          if (options[i] === configAns) {
            return true;
          }
          return false;
        })
      );
      setLastQuestions([])
    }
  };

  const changeConfigAnswer = (key, opt) => {
    if (!usecaseQuestions) {
      return;
    }
    const baseQuestionKeys = usecaseQuestions.map(q => q.key);
    const thresholdKeys = usecaseQuestions.map(q => `${q.key}_thresholds`);
    const configKeys = [
      'model_config.default_findings_thresholds',
      'model_config.findings_for_notifying_thresholds',
      'model_config.findings_for_ignoring_thresholds',
      'model_config.findings_for_notifying',
      'model_config.findings_for_ignoring'
    ];
    // Combining all keys into one array without modifying the original arrays
    const clearQuestionKeys = [...baseQuestionKeys, ...thresholdKeys, ...configKeys];
    setError('')
    changeAnswer(key, opt, clearQuestionKeys);
    handleSetNextQuestions();
  };

  const changeUseCaseAnswer = (key, opt) => {
    const clearQuestionKeys = lastQuestions?.reduce((final, q) => {
      return [...final, q.key, `${q.key}_thresholds`];
    }, []);
    setError('')
    clearQuestionKeys.push('model_config.default_findings_thresholds')
    changeAnswer(key, opt, clearQuestionKeys);
    handleSetNextQuestions();
  };

  const getExemptedFindings = (question) => {
    const dependentKey = get(question, "dependentKey", null);
    if (dependentKey) {
      return get(answers, dependentKey, {});
    }
    return {};
  };

  return (
    <div className="flex w-full">
      <div className="w-9/12">
        <Question
          questionData={configQuestion}
          serialNumber={1}
          changeAnswer={changeConfigAnswer}
          answer={get(answers, configQuestion.key, null)}
        />
        {usecaseQuestions?.map((question, i) => {
          return <Question
            key={question.key}
            questionData={question}
            serialNumber={2}
            changeAnswer={changeUseCaseAnswer}
            answer={get(answers, 'model_config.primary_focus', null)}
          />
        })
        }
        {error ? <div className="flex justify-center mt-7">
          <Image
            src="/assets/icons/errorMark.svg"
            alt="errorMark"
            width="18"
            height="18"
          />
          <span className="ml-2">{error}</span>
        </div>
          :
          lastQuestions?.map((question, i) => (
            <Question
              modelTitle={question.modelTitle}
              key={question.key}
              questionData={question}
              serialNumber={i + 3}
              changeAnswer={changeAnswer}
              allFindings={allFindings}
              setAllFindings={setAllFindings}
              answer={get(answers, question.key, null)}
              defaultFindings={get(answers, `model_config.default_findings_thresholds`, {})}
              findings={get(answers, `${question.key}_thresholds`, {})}
              exemptedFindings={getExemptedFindings(question)}
            />
          ))}
      </div>

      {(!isEmpty(usecaseQuestions) && !isEmpty(lastQuestions)) &&
        <div className="h-[30rem] text overflow-y-auto bg-gray-800 w-64 border rounded-md text-left">
          {usecaseQuestions?.map(
            (question) => (
              <SelectedTags key={question.key} header={question.tagSection.header} answers={answers} answerkey={question.tagSection.answerkey} />
            )
          )}
          {lastQuestions?.map(
            (question) => (
              <SelectedTags key={question.key} header={question.tagSection.header} answers={answers} answerkey={question.tagSection.answerkey} />
            )
          )}
        </div>
      }

    </div>
  );
};

export default ConfigurationForm;