import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import useCheckWorklistContainer from "../../hooks/useCheckWorklistContainer";
import useMedia, { MediaSizes } from "../../hooks/useMedia";
import TooltipArrow from './TooltipArrow'

const arrowPositionStyle = {
  bottom: {
    top: 0,
    transform: "translateY(-100%)",
  },
  top: {
    top: "100%",
    transform: "rotate(180deg)",
  },
  "bottom-left": { top: -8, left: "calc(-50% + 8px)" },
  "bottom-right": { top: -15, right: 5 },
  right: {
    top: "calc(50% - 8px)",
    left: -15,
    transform: "rotate(270deg)",
  },
  left: {
    top: "calc(50% - 8px)",
    right: -15,
    transform: "rotate(-270deg)",
  },
};

const Tooltip = ({
  content,
  isSticky,
  position,
  tight,
  children,
  isDisabled,
  className,
}) => {
  const [isActive, setIsActive] = useState(false);
  const isMobile = useMedia([MediaSizes.MOBILE], [true], false);
  const isWorkList = useCheckWorklistContainer();

  const handleMouseOver = () => {
    if (!isActive) {
      setIsActive(true);
    }
  };

  const handleMouseOut = () => {
    if (isActive) {
      setIsActive(false);
    }
  };

  const isOpen = (isSticky || isActive) && !isDisabled;
  const isMobileLayout = isMobile;

  return (
    <div
      className={classnames("relative", className)}
      onMouseEnter={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onBlur={handleMouseOut}
      role="tooltip"
    >
      {children}
      {!_.isEmpty(content) && (
        <div
          className={classnames(`cursor-pointer tooltip tooltip-${position} z-[9999]`, {
            block: isOpen,
            hidden: !isOpen || isMobileLayout,
          })}
        >
          <div
            className={classnames(
              "tooltip-box h-full bg-gray-700 borders border-gray-700 text-white text-sm rounded inset-x-auto top-full w-max",
              {
                "py-1 px-4": !tight,
              }
            )}
          >
            {typeof content === "string" ? content : content}
          </div>
          <TooltipArrow arrowPosition={arrowPositionStyle[position]}/>
        </div>)}
    </div>

  );
};

Tooltip.defaultProps = {
  tight: false,
  isSticky: false,
  position: "bottom",
  isDisabled: false,
};

Tooltip.propTypes = {
  /** prevents tooltip from rendering despite hover/active/sticky */
  isDisabled: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  position: PropTypes.oneOf(["bottom", "bottom-left", "bottom-right", "left", "right"]),
  className: PropTypes.string,
  isSticky: PropTypes.bool,
  tight: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
