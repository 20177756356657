import { useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";
function useWorkspacesData(_ref) {
  var isOpen = _ref.isOpen;
  console.log(isOpen);
  return useQuery("workspaceData", function () {
    return servicesUtils.getService("/workspaces/");
  }, {
    enabled: isOpen
  });
}
export default useWorkspacesData;