import React from 'react'
import _ from "lodash";

function ToBeRead({ pageContent }) {
    const headers = _.get(pageContent, ["headers"]);
    const report = _.get(pageContent, ["report"]);
    const rootDirPath = _.get(pageContent, ["files_path", "root_dir_path"], "");
    const thumbnailPath = `${rootDirPath ? `/${rootDirPath}/` : "/"}${_.get(
        pageContent,
        ["files_path", "thumbnail_path"]
    )}`;

    return (
        <section className="py-8 space-y-4 justify-between flex flex-col">
            <div className="grid grid-cols-2 gap-6">
                <div>
                    <div className="text-sm">
                        <h2 className="font-semibold">{_.startCase(_.toLower(headers.header_impression))}:</h2>
                        <p className='text-xs text-red-600'>{report.impression}</p>
                    </div>
                </div>
                <div className="">
                    <img
                        src={thumbnailPath}
                        alt={thumbnailPath}
                        className="w-full h-56 rounded-md object-contain"
                    />
                </div>
            </div>
        </section>
    );
}

export default ToBeRead